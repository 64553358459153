
const pieChart = {
    series: [3,0.2,10],
    chartOptions: {
        labels: ["Approved", "Rejected", "Incomplete"],
        colors: ["#5cb85c", "#dc3545", "#f1b44c"],
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    height: 240
                },
                legend: {
                    show: false
                },
            }
        }]
    }
};



export { pieChart };