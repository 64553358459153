<script>
import Layout from "../../layouts/main";
import { pieChart } from "./dashboard-chart";
import axios from 'axios';


import base_domain from '../../myvars'
axios.defaults.baseURL = base_domain


export default {

    components: {
        Layout
    },

    data() {
        return {
            Total_Approved_Applications:0,
            Total_Rejected_Applications:0,
            Total_Incomplete_Applications:0,
            duration: 2000,
            interval: 50,
            pieChart: pieChart,
            show_pie:false,
            arrItem:[]
        }
    },
    // computed: {
    //     pieChartSeries() {
    //     const acceptedCount = this.arrItem.filter(item => item.statusLog === 'approved').length;
    //     const rejectedCount = this.arrItem.filter(item => item.statusLog === 'rejected').length;
    //     const incompleteCount = this.arrItem.filter(item => item.statusLog === 'incomplete').length;

    //     return [acceptedCount, rejectedCount, incompleteCount];
    //     },
    // },
    created()
        {
            this.arrItem = this.items
        },
    // watch: {
    //     arrItem: {
    //     immediate: true,
    //     handler(newArrItem) {
    //         const acceptedCount = newArrItem.filter(item => item.statusLog === 'approved').length;
    //         const rejectedCount = newArrItem.filter(item => item.statusLog === 'rejected').length;
    //         const incompleteCount = newArrItem.filter(item => item.statusLog === 'incomplete').length;

    //         this.pieChart.series = [acceptedCount, rejectedCount, incompleteCount];
    //     },
    //     deep: true,
    //     },
    // },
    methods: {
        filterStatus(){
            if(this.selectFilterSort == null)
                {
                    this.arrItem = this.items
                }
            else{
                this.arrItem = this.items.filter((item)=>{
                    return item.statusLog == this.selectFilterSort
                })
            }
        },
    fetchData() {
        axios.get('/applications/all_member_count/')
            .then(response => {
                const data = response.data;
                console.log('cheack the data came or not ',data)
                this.Total_Staff_Members = data.Total_Staff_Members;
                this.Total_Applicants = data.Total_Applicants;
                this.Total_Approved_Applications = data.Total_Approved_Applications;
                this.Total_Rejected_Applications = data.Total_Rejected_Applications;
                this.Total_Incomplete_Applications = data.Total_Incomplete_Applications;
                this.Submitted_and_Under_Review = data.Submitted_and_Under_Review;
                console.log('this is for data coming or not-------- ',response.data['data'])
                // this.arrItem = arrList
                this.pieChart.series = response.data['data'];

                // var data = response.data['piechart_details'];[1,55,66,88,44,22]
                var sum  = 0;
                for (var i=0;i<data['data'].length;i++){ sum += data['data'][i] }
                if(sum>0){
                    this.pieChart.series = data['data'];
                    this.show_pie = true;
                }
                else { this.pieChart.series = [1,1,1] }
            })
            .catch(error => {
            console.error(error);
            });
        },
    // showdata(){
    //     let arrList = []
    //     axios.post('/applications/all_application_details/' ,{token: localStorage.getItem("accessToken")})
    //         .then(response => {
            
    //         const user_data = response.data;
    //         console.log('this is for yes check',user_data)
    //         let responseList = response.data['all_details'];
    //             responseList.forEach(item => {
    //             console.log('this i',item.status)
    //             let newItem = {
    //                 applicantNum: item.application_no,
    //                 assignPrsn:item.applicant,
    //                 dateLog:item.created_on,
    //                 statusLog:item.status,
    //                 actionBtnUrl:"#",
    //             };
    //             arrList.push(newItem);
    //             });
    //             this.arrItem = arrList
    //             this.pieChart.series = response.data['piechart_details'];

    //             var data = response.data['piechart_details'];
    //             var sum  = 0;
    //             data.forEach(e=>{ sum += e });
    //             if(sum>0){
    //                 this.pieChart.series = data;
    //                 this.show_pie = true;
    //             }
    //             else { this.pieChart.series = [1,1,1,1,1,1] }
    //         })
    //         .catch(error => {
    //         console.error(error);
    //         });
    //     }
    },

    mounted() {
       this.fetchData();
    //    this.showdata();
    }

};

</script>

<template>
    <Layout>
        <main class="py-4">

            <div class="row mb-3">
                <div class="col">
                    <br><br>
                    <h2 class="font-size-22 text-theme-dark">Application Stats</h2>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-4">
                            <router-link to="/approved_application" title="">
                                <div class="card stats-card approved-card">
                                    <div class="card-body overflow-hidden position-relative">
                                        <i class="mdi mdi-account-check fa-2x stats-back-img"></i>
                                        <h2 class="text-center counter-value mb-0">{{ Total_Approved_Applications }}</h2>
                                    </div>
                                    <div class="card-footer">
                                        <p class="text-theme-dark mb-0 text-center">Approved Applications</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-md-4">
                            <router-link to="/rejected_application">
                                <div class="card stats-card rejected-card">
                                    <div class="card-body overflow-hidden position-relative">
                                        <i class="mdi mdi-account-cancel fa-2x stats-back-img"></i>
                                        <h2 class="text-center mb-0">{{ Total_Rejected_Applications }}</h2>
                                    </div>
                                    <div class="card-footer ">
                                        <p class="text-theme-dark mb-0 text-center">Rejected Applications</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-md-4">
                            <router-link to="/incomplete_application">
                                <div class="card stats-card incomplete-card">
                                    <div class="card-body overflow-hidden position-relative">
                                        <i class="mdi mdi-account-clock fa-2x stats-back-img"></i>
                                        <h2 class="text-center mb-0">{{ Total_Incomplete_Applications }}</h2>
                                    </div>
                                    <div class="card-footer">
                                        <p class="text-theme-dark mb-0 text-center">Incomplete Applications</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body" v-if="show_pie">
                            <h3 class="card-title">Application Stats</h3>
                            <div class="p-3">
                                <apexchart
                                    class="apex-charts"
                                    height="289"
                                    type="pie"
                                    dir="ltr"
                                    :series="pieChart.series"
                                    :options="pieChart.chartOptions"
                                    >
                                </apexchart>
                            </div>
                        </div>
                        <div class="card-body" v-else>
                            <p class="card-title">Charts for Accepted, Rejected and incomplete data will be shown here.</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </Layout>
</template>